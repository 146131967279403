@import '../abstracts/variables';
@import '../abstracts/functions';
@import '../abstracts/mixins';

.sec-error {
  position: relative;
  $this: '.error';
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  background-color: #000;
  color: #fff;

  #{$this}--txt__wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
  }

  #{$this}--tit {
    font-size: 250rem;
    line-height: 0.88;
    letter-spacing: -8rem;
    font-weight: 500;
    font-family: $fruit-family;
    margin-bottom: 16rem;
    position: relative;
    top: 3rem;
    @include only-tablet {
      font-size: 150rem;
      line-height: 1;
      letter-spacing: -3rem;
      margin-bottom: 0;
    }
    @include only-mobile {
      font-size: 90rem;
      line-height: 1;
      letter-spacing: -0.5rem;
      margin-bottom: 8rem;
      top: 4rem;
    }
  }

  #{$this}--desc {
    font-size: 24rem;
    text-transform: uppercase;
    line-height: 1.08;
    letter-spacing: -0.4rem;
    margin-bottom: 39rem;
    position: relative;
    top: -4rem;
    @include only-tablet {
      font-size: 16rem;
      line-height: 1.25;
      letter-spacing: -0.1rem;
      margin-bottom: 29rem;
      top: 0;
    }
    @include only-mobile {
      font-size: 14rem;
      line-height: 0.93;
      letter-spacing: -0.1rem;
      margin-bottom: 23rem;
      top: 0;
    }
  }

  #{$this}--btn__wrap {
    position: relative;
    top: 3rem;
    @include only-tablet {
      top: 0;
    }
    @include only-mobile {
      top: 0;
    }
    .btn--home {
      display: inline-flex;
      justify-content: center;
      width: 145rem;
      padding: 19rem 0;
      border-radius: 32rem;
      border: 1px solid #fff;
      font-weight: 400;
      font-size: 16rem;
      line-height: 1;
      letter-spacing: normal;
      transition: all 0.3s ease;
      font-family: $grotesk-family;

      &:hover {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
      }
      @include only-tablet {
        width: 114rem;
        padding: 17rem 0;
        letter-spacing: -0.1rem;
        font-size: 12rem;
        line-height: 1;
      }
      @include only-mobile {
        width: 108rem;
        padding: 13rem 0;
        font-size: 12rem;
        line-height: 1;
        letter-spacing: -0.1rem;
      }
    }
  }

  #{$this}--footer__wrap {
    $footer: '.footer';
    position: relative;
    padding: 0 20rem 30rem 20rem;
    width: 100%;
    text-align: center;
    @include only-tablet {
      padding: 0 15rem 21rem 15rem;
    }
    @include only-mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 0 29rem 0;
    }

    .header__menu-info-txt {
      font-size: 18rem;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 1.11;
      letter-spacing: 0.1rem;
      font-family: $grotesk-family;
      @include only-tablet {
        font-size: 14rem;
        line-height: normal;
      }
      @include only-mobile {
        font-size: 11rem;
        line-height: normal;
        letter-spacing: normal;
      }

      &:last-child {
        @include only-mobile {
          margin-bottom: 0;
        }
      }
    }

    .footer__copy-txt--mobile {
      @include only-mobile {
        position: relative;
        top: auto;
        right: auto;
        order: 3;
      }
    }
  }
}

.footer {
  display: none;
}

// 한국어
[lang='ko'] .sec-error {
  .error--tit {
    margin-bottom: 0;
  }
  .error--desc {
    font-family: $pretendard-family;
    font-size: 24rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.2rem;
    top: 8rem;
    @include only-tablet {
      font-size: 16rem;
      line-height: 1.25;
      letter-spacing: -0.2rem;
      position: relative;
      top: -2rem;
    }
    @include only-mobile {
      font-size: 14rem;
      line-height: 1;
      letter-spacing: -0.2rem;
      top: 7rem;
    }
  }
  .error--btn__wrap {
    top: 8rem;
    text-align: center;
    @include only-tablet {
      top: -1rem;
    }
    @include only-mobile {
      top: 7rem;
    }

    .btn--home {
      letter-spacing: -0.3rem;
      @include only-tablet {
        letter-spacing: normal;
      }
      @include only-mobile {
        letter-spacing: normal;
      }
    }
  }
}
