.sec-error {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  background-color: #000;
  color: #fff;
}
.sec-error .error--txt__wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}
.sec-error .error--tit {
  font-size: 250rem;
  line-height: 0.88;
  letter-spacing: -8rem;
  font-weight: 500;
  font-family: "cardinalfruit", "pretendard", sans-serif;
  margin-bottom: 16rem;
  position: relative;
  top: 3rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .sec-error .error--tit {
    font-size: 150rem;
    line-height: 1;
    letter-spacing: -3rem;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 750px) {
  .sec-error .error--tit {
    font-size: 90rem;
    line-height: 1;
    letter-spacing: -0.5rem;
    margin-bottom: 8rem;
    top: 4rem;
  }
}
.sec-error .error--desc {
  font-size: 24rem;
  text-transform: uppercase;
  line-height: 1.08;
  letter-spacing: -0.4rem;
  margin-bottom: 39rem;
  position: relative;
  top: -4rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .sec-error .error--desc {
    font-size: 16rem;
    line-height: 1.25;
    letter-spacing: -0.1rem;
    margin-bottom: 29rem;
    top: 0;
  }
}
@media screen and (max-width: 750px) {
  .sec-error .error--desc {
    font-size: 14rem;
    line-height: 0.93;
    letter-spacing: -0.1rem;
    margin-bottom: 23rem;
    top: 0;
  }
}
.sec-error .error--btn__wrap {
  position: relative;
  top: 3rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .sec-error .error--btn__wrap {
    top: 0;
  }
}
@media screen and (max-width: 750px) {
  .sec-error .error--btn__wrap {
    top: 0;
  }
}
.sec-error .error--btn__wrap .btn--home {
  display: inline-flex;
  justify-content: center;
  width: 145rem;
  padding: 19rem 0;
  border-radius: 32rem;
  border: 1px solid #fff;
  font-weight: 400;
  font-size: 16rem;
  line-height: 1;
  letter-spacing: normal;
  transition: all 0.3s ease;
  font-family: "abcmonumentgrotesk", "pretendard", sans-serif;
}
.sec-error .error--btn__wrap .btn--home:hover {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .sec-error .error--btn__wrap .btn--home {
    width: 114rem;
    padding: 17rem 0;
    letter-spacing: -0.1rem;
    font-size: 12rem;
    line-height: 1;
  }
}
@media screen and (max-width: 750px) {
  .sec-error .error--btn__wrap .btn--home {
    width: 108rem;
    padding: 13rem 0;
    font-size: 12rem;
    line-height: 1;
    letter-spacing: -0.1rem;
  }
}
.sec-error .error--footer__wrap {
  position: relative;
  padding: 0 20rem 30rem 20rem;
  width: 100%;
  text-align: center;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .sec-error .error--footer__wrap {
    padding: 0 15rem 21rem 15rem;
  }
}
@media screen and (max-width: 750px) {
  .sec-error .error--footer__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 29rem 0;
  }
}
.sec-error .error--footer__wrap .header__menu-info-txt {
  font-size: 18rem;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.1rem;
  font-family: "abcmonumentgrotesk", "pretendard", sans-serif;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  .sec-error .error--footer__wrap .header__menu-info-txt {
    font-size: 14rem;
    line-height: normal;
  }
}
@media screen and (max-width: 750px) {
  .sec-error .error--footer__wrap .header__menu-info-txt {
    font-size: 11rem;
    line-height: normal;
    letter-spacing: normal;
  }
}
@media screen and (max-width: 750px) {
  .sec-error .error--footer__wrap .header__menu-info-txt:last-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 750px) {
  .sec-error .error--footer__wrap .footer__copy-txt--mobile {
    position: relative;
    top: auto;
    right: auto;
    order: 3;
  }
}

.footer {
  display: none;
}

[lang=ko] .sec-error .error--tit {
  margin-bottom: 0;
}
[lang=ko] .sec-error .error--desc {
  font-family: "pretendard", "abcmonumentgrotesk", sans-serif;
  font-size: 24rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.2rem;
  top: 8rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] .sec-error .error--desc {
    font-size: 16rem;
    line-height: 1.25;
    letter-spacing: -0.2rem;
    position: relative;
    top: -2rem;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] .sec-error .error--desc {
    font-size: 14rem;
    line-height: 1;
    letter-spacing: -0.2rem;
    top: 7rem;
  }
}
[lang=ko] .sec-error .error--btn__wrap {
  top: 8rem;
  text-align: center;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] .sec-error .error--btn__wrap {
    top: -1rem;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] .sec-error .error--btn__wrap {
    top: 7rem;
  }
}
[lang=ko] .sec-error .error--btn__wrap .btn--home {
  letter-spacing: -0.3rem;
}
@media screen and (min-width: 751px) and (max-width: 1024px) {
  [lang=ko] .sec-error .error--btn__wrap .btn--home {
    letter-spacing: normal;
  }
}
@media screen and (max-width: 750px) {
  [lang=ko] .sec-error .error--btn__wrap .btn--home {
    letter-spacing: normal;
  }
}